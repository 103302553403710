import React from "react";

const IconEmail = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="28"
      width="28"
      viewBox="0 0 48 48"
    >
      <path d="M39.75 42Q33.65 42 27.625 39Q21.6 36 16.8 31.2Q12 26.4 9 20.375Q6 14.35 6 8.25Q6 7.3 6.65 6.65Q7.3 6 8.25 6H15.25Q15.95 6 16.475 6.475Q17 6.95 17.15 7.75L18.5 14.05Q18.6 14.75 18.475 15.325Q18.35 15.9 17.95 16.3L12.95 21.35Q15.75 26 19.225 29.45Q22.7 32.9 27.1 35.3L31.85 30.4Q32.35 29.85 33 29.625Q33.65 29.4 34.3 29.55L40.25 30.85Q41 31 41.5 31.6Q42 32.2 42 33V39.75Q42 40.7 41.35 41.35Q40.7 42 39.75 42ZM11.45 18.6 15.5 14.5Q15.5 14.5 15.5 14.5Q15.5 14.5 15.5 14.5L14.35 9Q14.35 9 14.35 9Q14.35 9 14.35 9H9Q9 9 9 9Q9 9 9 9Q9 10.95 9.6 13.275Q10.2 15.6 11.45 18.6ZM39 39Q39 39 39 39Q39 39 39 39V33.65Q39 33.65 39 33.65Q39 33.65 39 33.65L33.85 32.6Q33.85 32.6 33.85 32.6Q33.85 32.6 33.85 32.6L29.9 36.75Q31.95 37.7 34.35 38.3Q36.75 38.9 39 39ZM29.9 36.75Q29.9 36.75 29.9 36.75Q29.9 36.75 29.9 36.75Q29.9 36.75 29.9 36.75Q29.9 36.75 29.9 36.75Q29.9 36.75 29.9 36.75Q29.9 36.75 29.9 36.75Q29.9 36.75 29.9 36.75Q29.9 36.75 29.9 36.75ZM11.45 18.6Q11.45 18.6 11.45 18.6Q11.45 18.6 11.45 18.6Q11.45 18.6 11.45 18.6Q11.45 18.6 11.45 18.6Q11.45 18.6 11.45 18.6Q11.45 18.6 11.45 18.6Q11.45 18.6 11.45 18.6Q11.45 18.6 11.45 18.6Z" />
    </svg>
  );
};

export default IconEmail;
