import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";
import Logo from "../components/Icons/Logo";
import HeroImage from "../images/hero_image_c.jpg";
import { Helmet } from "react-helmet";

import AviationImage from "../images/aviation.jpeg";

import "./index.css";
import IconEmail from "../components/IconContact";
import IconContact from "../components/IconContact";
const HeroPage = {};

const LogoStyle = {
  width: "120px",
};

const HeaderStyle = {
  width: "100%",
  position: "fixed",
  zIndex: 999,
  padding: "24px 0",
  background: "black",
};

const OverlayStyle = {
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  backgroundColor: "black",
  opacity: 0.7,
  bottom: 0,
};

const ContainerStyle = {
  maxWidth: "960px",
  margin: "auto",
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "row",
  alignItems: "center",
};

const TitleStyle = {
  fontWeight: 600,
  fontSize: "30px",
  margin: "10px 0",
};

const HeroStyle = {
  minHeight: "100vh",
  backgroundImage: `url(${HeroImage})`,
  width: "100%",
  backgroundSize: "cover",
};

const LinkStyle = {
  fontSize: "16px",
  color: "white",
  marginLeft: "32px",
  textDecoration: "none",
};

const menu = [
  {
    name: "About Us",
    link: "/#aboutus",
  },
  {
    name: "Area Of Practice",
    link: "/#area",
  },
  {
    name: "Our People",
    link: "/#people",
  },
  {
    name: "Contact",
    link: "/#contact",
  },
];

const MenuStyle = {};

const CardLayout = {
  width: "31%",
  margin: "1%",
  borderRadius: "5px",
};

const IndexPage = () => {
  const areaOfPractice = [
    {
      title: "Aviation",
      description:
        "We have comprehensive knowledge and understanding of the international aviation industry, such as aviation litigation, aircraft finance, and aviation",
    },
    {
      title: "Restructuting and Bankcruptcy",
      description:
        "We assist clients on debt, restructuring, preparing and negotiating agreements, postponement of debt settlement obligations.",
      image: "../images/aviation.jpeg",
    },
    {
      title: "Merger and Acquisition",
      description:
        "We assist clients in every aspect of, commercial transactions including conducting, advising on due diligence, pre-deal negotiations, closing & post-deal structuring, and integration. Maintaining the round understanding of substantive legal matters.",
      image: "../images/aviation.jpeg",
    },
  ];
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Erpy Brothers</title>
        <meta
          name="facebook-domain-verification"
          content="vuplm77m2th1yea26y2a2wggbs27wo"
        />
        <link rel="canonical" href="https://erpy.lawyer" />
      </Helmet>
      <div style={HeaderStyle}>
        <div style={ContainerStyle}>
          <div style={LogoStyle}>
            <Logo />
          </div>
          <div style={MenuStyle}>
            {menu.map((d) => (
              <a href={d.link} style={LinkStyle}>
                {d.name}
              </a>
            ))}
          </div>
        </div>
      </div>
      <div style={HeroStyle}>
        <div style={OverlayStyle}></div>
      </div>
      <div
        data-sal="fade"
        data-sal-delay="300"
        data-sal-duration="1000"
        data-sal-easing="ease"
        className="content"
      >
        <div style={{ textAlign: "left", width: "50%" }}>
          <h3 style={TitleStyle}>About Us</h3>
          <p style={{ lineHeight: "22px" }}>
            Erpy brothers basically is a law firm but in complex, we are your
            problem solver. What make us different is our deep understanding and
            solution with “more for less” issue. In other words we provide
            inclusive and exclusive service.
          </p>
        </div>
      </div>
      <div className="content">
        <div className="areaOfPractice">
          <h3 style={{ ...TitleStyle, width: "20%" }}>Area Of Practice</h3>
          <div className="areaOfPracticeContent">
            <div
              data-sal="slide-left"
              data-sal-delay="100"
              data-sal-duration="1000"
              data-sal-easing="ease"
              className="CardLayout"
            >
              <div className="CardImageContainerLayout">
                <StaticImage src={"../images/aviation.jpeg"} />
              </div>
              <h4 className="CardTitleLayout">Aviation</h4>
              <p className="CardDescriptionLayout">
                We have comprehensive knowledge and understanding of the
                international aviation industry, such as aviation litigation,
                aircraft finance, and aviation
              </p>
            </div>
            <div
              data-sal="slide-left"
              data-sal-delay="200"
              data-sal-duration="1000"
              data-sal-easing="ease"
              className="CardLayout"
            >
              <div
                className="CardImageContainerLayout"
                style={{
                  height: 0,
                  paddingBottom: "127%",
                }}
              >
                <StaticImage
                  height="300"
                  src={"../images/bankrupt.jpg"}
                  layout="fixed"
                />
              </div>
              <h4 className="CardTitleLayout">Restructuring and Bankcruptcy</h4>
              <p className="CardDescriptionLayout">
                We assist clients on debt, restructuring, preparing and
                negotiating agreements, postponement of debt settlement
                obligations.
              </p>
            </div>
            <div
              data-sal="slide-left"
              data-sal-delay="300"
              data-sal-duration="1000"
              data-sal-easing="ease"
              className="CardLayout"
            >
              <div className="CardImageContainerLayout">
                <StaticImage src={"../images/oil.jpg"} />
              </div>
              <h4 className="CardTitleLayout">Oil and Gas</h4>
              <p className="CardDescriptionLayout">
                We have been assisting oil and gas companies with exploration
                transactions, rig drilling, and service agreements.
              </p>
            </div>
            <div
              data-sal="slide-left"
              data-sal-delay="400"
              data-sal-duration="1000"
              data-sal-easing="ease"
              className="CardLayout"
              style={{ width: "48%", margin: "1%" }}
            >
              <div
                className="CardImageContainerLayout"
                style={{ paddingBottom: "80%" }}
              >
                <StaticImage src={"../images/merger.jpg"} />
              </div>
              <h4 className="CardTitleLayout">Merger and Acquisition</h4>
              <p className="CardDescriptionLayout">
                We assist clients in every aspect of, commercial transactions
                including conducting, advising on due diligence, pre-deal
                negotiations, closing & post-deal structuring, and integration.
                Maintaining the round understanding of substantive legal
                matters.
              </p>
            </div>
            <div
              data-sal="slide-left"
              data-sal-delay="500"
              data-sal-duration="1000"
              data-sal-easing="ease"
              className="CardLayout"
              style={{ width: "48%", margin: "1%" }}
            >
              <div
                className="CardImageContainerLayout"
                style={{ paddingBottom: "80%" }}
              >
                <StaticImage
                  height="300"
                  layout="fixed"
                  src={"../images/dispute.jpg"}
                />
              </div>
              <h4 className="CardTitleLayout">Dispute Resolution</h4>
              <p className="CardDescriptionLayout">
                Dispute resolution probably is an extremely complicated issue
                considering a lot of game theory involved. We have extensive
                experience with dispute resolution, resolving it with
                alternative dispute resolution or litigation.
              </p>
            </div>
            <div
              data-sal="slide-left"
              data-sal-delay="600"
              data-sal-duration="1000"
              data-sal-easing="ease"
              className="CardLayout"
              style={{ width: "48%", margin: "1%" }}
            >
              <div
                className="CardImageContainerLayout"
                style={{ paddingBottom: "80%" }}
              >
                <StaticImage
                  height="300"
                  layout="fixed"
                  src={"../images/labor.jpg"}
                />
              </div>
              <h4 className="CardTitleLayout">Labor and Employment</h4>
              <p className="CardDescriptionLayout">
                In Indonesia, Labor and employment are one of the primary issues
                specifically the dispute. We have been representing numerous
                corporate in both litigation and non-litigation.
              </p>
            </div>

            <div
              data-sal="slide-left"
              data-sal-delay="700"
              data-sal-duration="1000"
              data-sal-easing="ease"
              className="CardLayout"
              style={{ width: "48%", margin: "1%" }}
            >
              <div
                className="CardImageContainerLayout"
                style={{ paddingBottom: "80%" }}
              >
                <StaticImage
                  height="300"
                  layout="fixed"
                  src={"../images/tax.jpg"}
                />
              </div>
              <h4 className="CardTitleLayout">Tax</h4>
              <p className="CardDescriptionLayout">
                We provide a full range of legal, tax, and customs service to
                individual persons and companies regarding current tax law and
                policy.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="ourPeople">
          <h3 className="titleStyle">Our People</h3>
          <div className="areaOfPracticeContent">
            <div
              data-sal="slide-left"
              data-sal-delay="100"
              data-sal-duration="1000"
              data-sal-easing="ease"
              className="CardLayoutPeople"
            >
              <div className="CardImageContainerLayoutPeople">
                <StaticImage src={"../images/deo.jpg"} />
              </div>
              <div className="CardContainerDescriptionLayout">
                <h4 className="CardTitleLayoutPeople">ANDRY LANTARA, S.H</h4>
                <h5 className="CardSubtitleLayout">CONSULTANT</h5>
                <p className="CardDescriptionLayout">
                  Andry is an Universitas Islam Indonesia alumni with multiple
                  experiences in corporate and particularly in dispute
                  resolution. he has been handling various negotiations, proxy
                  wars, and other types of disputes. Extremely fascinated with
                  startup world, disruptive innovation, AI, crypto, blockchain
                  and data after he learned it from Legal and Tech education
                  program, organized by Jimly School Law and Government.
                </p>
              </div>
            </div>
            <div
              data-sal="slide-left"
              data-sal-delay="200"
              data-sal-duration="1000"
              data-sal-easing="ease"
              className="CardLayoutPeople reverse"
            >
              <div className="CardImageContainerLayoutPeople">
                <StaticImage src={"../images/mirel.jpg"} />
              </div>
              <div className="CardContainerDescriptionLayout descriptionReverse">
                <h4 className="CardTitleLayoutPeople">
                  ADMIRAL HERDI PRATAMA, S.H
                </h4>
                <h5 className="CardSubtitleLayout">LEGAL CONSULTANT</h5>
                <p className="CardDescriptionLayout">
                  Admiral is an aviation geek with cumlaude predicate from
                  Universitas Islam Indonesia. With experiences in corporate and
                  dispute, also knowledge about tax that he earned from the tax
                  education program, organized by Jimly School Law and
                  Government.
                </p>
              </div>
            </div>
            <div
              data-sal="slide-left"
              data-sal-delay="300"
              data-sal-duration="1000"
              data-sal-easing="ease"
              className="CardLayoutPeople"
            >
              <div className="CardImageContainerLayoutPeople descriptionReverse">
                <StaticImage src={"../images/marzuki.jpg"} />
              </div>
              <div className="CardContainerDescriptionLayout descriptionReverse">
                <h4 className="CardTitleLayoutPeople">MARZUKI RAUF, S.H</h4>
                <h5 className="CardSubtitleLayout">COUNSEL</h5>
                <p className="CardDescriptionLayout">
                  In the matters of property both legal and business, Marzuki
                  has it thoroughly in his mind. He leads and assisted prominent
                  property projects which also owned by prominent Indonesia
                  property companies regarding its legal matters for instance:
                </p>
                <ul>
                  <li>Rasuna Epicentrum by PT Bakrieland Development, Tbk.</li>
                  <li>
                    Cilandak Town Square (CITOS) by PT Graha Megaria Raya.
                  </li>
                  <li>
                    Construction of Industry area and port in bojonegoro by PT.
                    Pelindo II.
                  </li>
                  <li>Retail Mall Cikarang by PT Menara Inti Development.</li>
                  <li>Apartement Eminence by PT Prakarsa Semesta.</li>
                  <li>Auto Mall Kemayoran by PT Dapenbun Nusantara.</li>
                  <li>BG Junction by PT Keppel Land.</li>
                  <li>
                    Construction of housing project held by PT Sun motor and PT.
                    Lippo Cikarang.
                  </li>
                  <li>
                    Apartement Permata Berlian by PT Permata Berlian Realty/PT.
                    Pakuwon Jati
                  </li>
                  <li>
                    Office Buliding Menara Bosowa by PT Menara Bosowa. With all
                    of his experiences, he believes he can provide highest
                    quality service for clients which running the property
                    business.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div id="contact" className="areaOfPractice">
          <h3 className="titleStyle">Contact Us</h3>

          <div
            style={{ display: "flex", flexDirection: "column", gap: "16px" }}
          >
            <div className="listOfContact">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="28"
                width="28"
                viewBox="0 0 48 48"
              >
                <path d="M7 40Q5.8 40 4.9 39.1Q4 38.2 4 37V11Q4 9.8 4.9 8.9Q5.8 8 7 8H41Q42.2 8 43.1 8.9Q44 9.8 44 11V37Q44 38.2 43.1 39.1Q42.2 40 41 40ZM24 24.9 7 13.75V37Q7 37 7 37Q7 37 7 37H41Q41 37 41 37Q41 37 41 37V13.75ZM24 21.9 40.8 11H7.25ZM7 13.75V11V13.75V37Q7 37 7 37Q7 37 7 37Q7 37 7 37Q7 37 7 37Z" />
              </svg>
              <span>info@erpy.lawyer</span>
            </div>
            <div className="listOfContact">
              <IconContact />
              <span>+62 811 1358797</span>
            </div>
            <div className="listOfContact">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="28"
                width="28"
                viewBox="0 0 48 48"
              >
                <path d="M8 42V18L24.1 6L40 18V42H28.3V27.75H19.65V42ZM11 39H16.65V24.75H31.3V39H37V19.5L24.1 9.75L11 19.5ZM24 24.35Z" />
              </svg>
              <address>
                Gondangdia lama Building, 5th floor, unit 1
                <br />
                Jl. RP Soeroso No. 25, Cikini, Menteng, Central Jakarta.
              </address>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndexPage;
